//TODO handle case where worker is not defined in browser

function WorkerPool(url) {
    this.url = url;
    this.pool = [];
}
WorkerPool.prototype.getWorker = function() {
    var w;
    if (this.pool.length > 0) {
        w = this.pool.pop();
    } else {
      try{
        w = new Worker(this.url);
      }
      catch(e){
        console.error(e);
      }
    }
    return w;
}
WorkerPool.prototype.releaseWorker = function(w) {
    this.pool.push(w);
}
